// extracted by mini-css-extract-plugin
export var ArtistDescription = "RichardHambleton-module--ArtistDescription--VGMKl";
export var ArtistInfos = "RichardHambleton-module--ArtistInfos--hFBwN";
export var ButtonWrapper = "RichardHambleton-module--ButtonWrapper --U1EkT";
export var CardWrapper = "RichardHambleton-module--CardWrapper--Clf3+";
export var CarrouselWrapper2 = "RichardHambleton-module--CarrouselWrapper2--ePDAS";
export var Citations = "RichardHambleton-module--Citations--JDN3V";
export var DescriptionWrapper = "RichardHambleton-module--DescriptionWrapper--DYY1c";
export var ImageWrapper = "RichardHambleton-module--ImageWrapper--RyHDL";
export var LinkWrapper = "RichardHambleton-module--LinkWrapper--EH1tf";
export var MobileProtrait = "RichardHambleton-module--MobileProtrait--03eud";
export var More = "RichardHambleton-module--More--xLozI";
export var MoreButton = "RichardHambleton-module--MoreButton--Xyaji";
export var NameWrapper = "RichardHambleton-module--NameWrapper--NW6CV";
export var PdpWrapper = "RichardHambleton-module--PdpWrapper--PYDlq";
export var PhotosWrapper = "RichardHambleton-module--PhotosWrapper--lKDOb";
export var ProfilWrapper = "RichardHambleton-module--ProfilWrapper--1Nlbw";
export var TitleWrapper = "RichardHambleton-module--TitleWrapper--KcqMs";
export var Wrapper = "RichardHambleton-module--Wrapper--vzqyE";