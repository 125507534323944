import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
  CarrouselWrapper2,
  LinkWrapper,
  ButtonWrapper,
  TitleWrapper,
  ArtistInfos,
  ImageWrapper,
  MobileProtrait,
  MoreButton,
  More,
} from "./RichardHambleton.module.css";
import Title from "./../../../../components/Title";
import Carousel from 'react-multi-carousel';
import RichardHambletonPhoto2 from "../../../../res/Photos site/Richard Hambleton/Richard-Hambleton by Susan-Aimee-Weinik.jpeg.webp"
import RichardHambletonPhoto3 from "../../../../res/Photos site/Richard Hambleton/Richard Hambleton.jpg"
import RichardHambletonPhoto4 from "../../../../res/Photos site/Richard Hambleton/Richard Hambleton 2.jpg"
import RichardHambletonPhoto5 from "../../../../res/Photos site/Richard Hambleton/GG.jpg"
import RichardHambletonPhoto6 from "../../../../res/Photos site/Richard Hambleton/hambleton.jpeg"
import Button from "./../../../../components/Button";
import Pdp from '../../../../res/hambleton/portrait.jpg'
import PastPresent1 from "../../../../res/hambleton/past-present-1.jpg"
import PastPresent2 from "../../../../res/hambleton/past-present-2.jpg"
import PastPresent3 from "../../../../res/hambleton/past-present-3.jpg"
import { Helmet } from "react-helmet";

const isBrowser = typeof window !== "undefined";
const infos ={
  path: "RichardHambleton",
  name: "RichardHambleton",
  description:"Richard Hambleton est né en 1952 à Vancouver. Il étudie au Vancouver College of Art et gagne en notoriété après son diplôme avec ses silhouettes noires peintes à la main et de taille humaine, qu’il peint dans les rues pour imiter les poses des policiers. Il traverse le Canada et les États-Unis dans les années 1970 et peint plus de 620 silhouettes sur son passage. Son nom devient alors populaire, pas toujours apprécié auprès des critiques artistiques et des services de police locaux. Il s’installe à New York en 1980. Les murs de New York deviennent pour lui une toile vierge et il continue alors à recouvrir les rues labyrinthiques de la ville de ses petites silhouettes noires et menaçantes, un parfait écho au climat qui régnait dans la ville à une époque où New York était submergé par les crimes. « J’ai repeint la ville en noir » disait-il. Rapidement il se hisse en haut de la liste des figures incontournables du street art. Il vend de nombreuses oeuvres à travers l’Amérique et l’Europe, mais, hanté par la mort de nombreux de ses amis pendant l’épidémie du sida et désillusionné par la réalité du monde de l’art, Richard Hambleton prend sa retraite du public dans les années 1990. On dit souvent que Hambleton a été une référence pour l’artiste anglais Banksy.",
  pdp: Pdp,
  alt_pdp: "Photo de profil de RichardHambleton.",
  photos: [
    { src: PastPresent1, name: "RichardHambleton" },
    { src: PastPresent2, name: "RichardHambleton" },
    { src: PastPresent3, name: "RichardHambleton" },
  ],
  works: ["Works", "Works", "Works"],
  videos: ["Videos", "Videos", "Videos"],
  citations: [
  "« Je pense que j’ai découvert Richard de la même manière que la plupart des gens. Les gens parlent de lui en pensant à Haring et Basquiat, mais il est une figure beaucoup plus mystérieuse. Il est à la fois tristement célèbre et inconnu, et ça me fascine. Et il est plus pertinent que jamais aujourd’hui. Nous sommes tellement saturés par nos cycles de news, nos réseaux sociaux, qu’une histoire en chasse une autre, toujours plus choquante que la précédente – Mass Murder et Shadowman évoquent cette facette de notre psyché, celle qui n’est sûre de rien surtout pas de l’avenir – ça touche exactement au même nerf. » - Jay Rutland",
  ]
};

const RichardHambleton = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
      <Helmet>
        {
            isBrowser ? window.onscroll = function(e){
              var sel = document.querySelector('#f')
              var end = document.querySelector('#end').offsetTop - 450;
              if (window.scrollY > end) {
                  sel.style.position = 'absolute';
                  sel.style.top = end.toString()+ "px";
              } else {
                  sel.style.position = 'fixed';
                  sel.style.top = 0;
              }
            } : null
          }
      </Helmet>
      <div className={CardWrapper}>
        <div className={ProfilWrapper} id="f">
          <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
        </div>
        <div className={ArtistDescription}>
          <h1 className={TitleWrapper}>RICHARD HAMBLETON <h2 style={{paddingLeft: "16px"}}>(Canadien)</h2></h1>
          <h3 className={NameWrapper} >1952-2017</h3>
          <img className={MobileProtrait} src={infos.pdp} alt={infos.alt_pdp} />
          <div className={DescriptionWrapper} dangerouslySetInnerHTML={{__html:infos.description}}></div>
          <br />
          <div  className={Citations}>
            {infos.citations.map((citations) => (
              <div>
                <p style={{fontStyle: "italic", textAlign: "center" }}>{citations}</p>
                <br />
              </div>
            ))}
          </div>
          <br />
        <ul className={ArtistInfos}>
          <p style={{fontWeight: "bolder"}}>EDUCATION</p>
          <br />
          <p style={{fontWeight: "bold"}}>1975</p>
          <p>- Emily Carr University of Art and Design, Advanced Diploma (Vancouver, Canada)</p>
          <br />
          <br />
          <p style={{fontWeight: "bolder"}}>SOLO EXHIBITIONS</p>
          <br />
          <p style={{fontWeight: "bold"}}>2022</p>
          <p>- Richard Hambleton | Beyond the Shadowman, Chase Contemporary, New York City, New York, US, 5 - 29 Mai 2022.</p>
          <p>- Woodbury House x The Master Investor Show, Woodbury House</p>
          <p>- Woodbury House x The London Watch Show 2022, Woodbury House</p>
          <br />
          <p style={{fontWeight: "bold"}}>2021</p>
          <p>- Generation Y, SHIN GALLERY LOWER EAST SIDE, NEW YORK, USA, DEC 02,2020 - JAN 20,2021</p>
          <p>- Richard Hambleton: Shadowman, Chase Contemporary. USA, June. 1st - July 1st.</p>
          <p>- An Evening with the Shadowman, Woodbury House</p>
          <p>- Woodbury House x The London Watch Show 2021, Woodbury House</p>
          <p>- Art of Darkness: Banksy / Blek Le Rat / Richard Hambleton, Chase Contemporary</p>
          <p>- Richard Hambleton | Shadowman, Castle Fine Art</p>
          <div className={More} id="more">
          <br />
          <p style={{fontWeight: "bold"}}>2020</p>
          <p>- Richard Hambleton. Highlights from the Collection, CHASE CONTEMPORARY, NY (23RD STREET),CHELSEA, NEW YORK, USA, OCT 01,2020 - DEC 31,2020</p>
          <p>- Nightlife Saatchi, Woodbury House</p>
          <p>- Woodbury House presents – Richard Hambleton ‘Nightlife Preview’, Woodbury House</p>
          <br />
          <p style={{fontWeight: "bold"}}>2019</p>
          <p>- Richard Hambleton: Shadowman, Chase Contemporary. USA, October 7th - December 31st.</p>
          <p>- Richard Hambleton Life on Paper, Woodward Gallery</p>
          <br />
          <p style={{fontWeight: "bold"}}>2018</p>
          <p>- Richard Hambleton: Eternity, ACA GALLERIES CHELSEA | NEW YORK | USA, JUN 14,2018 - JUL 27,2018</p>
          <p>- 'Shadowman' at the Groucho Club, Woodbury House</p>
          <p>- Richard Hambleton - The Curtain Club, Woodbury House</p>
          <p>- Shadowman Private Screening, The Curtain Club, London</p>
          <br />
          <p style={{fontWeight: "bold"}}>2017</p>
          <p>- Richard Hambleton: I Only Have Eyes For You, Woodward Gallery. USA, April 4th - May 5th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2013</p>
          <p>- Richard Hambleton: Beautiful Paintings, curated by dir. John Woodward, Art Gallery at the Rockefeller State Park Preserve. Sleepy Hollow, USA, May 9th - June 23rd.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2011</p>
          <p>- Richard Hambleton: A Retrospective, presented by Andy Valmorbida and Vladimir Restoin Roitfeld in collaboration with Giorgio Armani at the Phillips de Pury & Co headquarters. USA, September 9th - 13th (Final stop of the travelling exhibition: Milan, Moscow, Cannes, London).</p>
          <br />
          <p style={{fontWeight: "bold"}}>2010</p>
          <p>- Richard Hambleton New York, The Godfather of Street Art, presented by Andy Valmorbida and Vladimir Restoin Roitfeld in collaboration with Giorgio Armani at The Dairy. London, United Kingdom, November 18th - December 3rd.</p>
          <p>- Richard Hambleton New York, presented by Andy Valmorbida and Vladimir Restoin Roitfeld in collaboration with Giorgio Armani at the State Museum of Modern Art of the Russian Academy of Arts. Moscow, Russia, September 9th - 26th.</p>
          <p>- Richard Hambleton New York, presented by Andy Valmorbida and Vladimir Restoin Roitfeld in collaboration with Giorgio Armani. amFAR Annual Benefit, New York, USA, May 12 th.</p>
          <p>- Richard Hambleton New York, presented by Andy Valmorbida and Vladimir Restoin Roitfeld in collaboration with Giorgio Armani. Milan, Italy, March 1st - 12th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2009</p>
          <p>- Richard Hambleton New York, presented by Andy Valmorbida and Vladimir Restoin Roitfeld in collaboration with Giorgio Armani. New York, USA, September 15th - October 2nd.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2007</p>
          <p>- The Beautiful Paintings, Woodward Gallery. New York, USA, September 15th - November 3rd.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1985</p>
          <p>- Piezo Electric Gallery. New York, USA.</p>
          <p>- Salvatore Ala Gallery. New York, USA.</p>
          <p>- Zellermayer Galerie. Berlin, Germany.</p>
          <p>- Museo De Arte Costaricense. Costa Rica, Spain.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1984</p>
          <p>- 121 Gallery. Antwerp, Belgium.</p>
          <p>- Anna Friebe Galerie, Cologne, Germany.</p>
          <p>- Galerie Barbara Farber, Amsterdam, The Netherlands.</p>
          <p>- Galerie Salvatore Ala, Milano, Italy.</p>
          <p>- Salvatore Ala Gallery, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1983</p>
          <p>- Piezo Electric, New York, USA.</p>
          <p>- Civilian Warefare, New York, USA.</p>
          <p>- Limbo Lounge, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1982</p>
          <p>- Alexander Milliken- East Village Gallery, New York, USA.</p>
          <p>- Flavio Belli Gallery, Toronto, Canada.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1980</p>
          <p>- La Mamelle, Inc. San Francisco, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1979</p>
          <p>- Pumps Center for Art, Vancouver, Canada.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1978</p>
          <p>- Western Front, Vancouver, Canada.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1977</p>
          <p>- Pumps Center for Art, Vancouver, Canada.</p>
          <br />
          <p style={{fontWeight: "bolder"}}>GROUP EXHIBITIONS</p>
          <br />
          <p style={{fontWeight: "bold"}}>2021</p>
          <p>- Icons, Iconoclasts, and Outsiders, HOWL! HAPPENING NEW YORK, USA, SEP 19,2021 - DEC 23,2021</p>
          <p>- Art of Darkness: Banksy, Blek Le Rat, Richard HambletoN,CHASE CONTEMPORARY, NEW YORK SOHO | NEW YORK | USA, SEP 18,2021 - OCT 10,2021</p>
          <p>- Five, MADDOX GALLERY, LONDON (MADDOX STREET) MAYFAIR, LONDON, UK, DEC 16,2020 - APR 09,2021</p>
          <br />
          <p style={{fontWeight: "bold"}}>2020</p>
          <p>- Sedecim XVI, WOODWARD GALLERY SOHO, NEW YORK, USA, NOV 22, 2019 - JAN 24,2020</p>
          <br />
          <p style={{fontWeight: "bold"}}>2019</p>
          <p>- Get Out While You Can, MADDOX GALLERY, GSTAAD GSTAAD | SWITZERLAND, JUL 18,2019 - AUG 31,2019</p>
          <p>- Studio In The Street : Symbols - Totems - Cyphers, NATIONAL ARTS CLUB GRAMERCY PARK, NEW YORK, USA, APR 29,2019 - JUN 14,2019</p>
          <p>- Spring Contemporary, MADDOX GALLERY, LONDON (SHEPHERD MARKET), UK, FEB 28,2019 - APR 30,2019</p>
          <p>- Winter Contemporary, MADDOX GALLERY, LONDON (MADDOX STREET) MAYFAIR, LONDON, UK, DEC 01,2018 - JAN 17,2019</p>
          <br />
          <p style={{fontWeight: "bold"}}>2018</p>
          <p>- Symbols, Totems & Ciphers, THE PAINTING CENTER SOHO, NEW YORK, USA, NOV 27,2018 - DEC 22,2018</p>
          <p>- Autumn Contemporary, MADDOX GALLERY, LONDON (MADDOX STREET) MAYFAIR, LONDON, UK, OCT 29,2018 - DEC 21,2018</p>
          <p>- Zeitgeist: The Art Scene of Teenage Basquiat, HOWL! HAPPENING NEW YORK, USA, MAY 13,2018 - JUN 10,2018</p>
          <p>- 68-18, SHIN GALLERY LOWER EAST SIDE, NEW YORK, USA, MAR 09,2018 - APR 22,2018</p>
          <p>- 20in18, WOODWARD GALLERY SOHO, NEW YORK, USA, FEB 08,2018 - MAR 23,2018</p>
          <p>- Long live the revolution, WUNDERKAMMERN GALLERY, ROME, ITALY, DEC 02,2017 - JAN 20,2018</p>
          <br />
          <p style={{fontWeight: "bold"}}>2017</p>
          <p>- Black and White, ANNA MARIA CONSADORI MILAN, ITALY, SEP 14,2017 - OCT 21,2017</p>
          <p>- Summer Group Show, ACA GALLERIES CHELSEA, NEW YORK, USA, JUN 15,2017 - AUG 11,2017</p>
          <br />
          <p style={{fontWeight: "bold"}}>2016</p>
          <p>- Scribble, Scratch, Paint- Once Removed- 13 Windows, 13 Artists- One Wall, The Windows at NYU Kimmel Gallery. New York, USA, June 10th - August 31st.</p>
          <p>- Fall Salon, Woodward Gallery. New York, USA, September 10th – October 28th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2015</p>
          <p>- Réelles Distorsions, Xippas Gallery, Andres Dobler/ Sylvie Fleury/ Richard Hambleton/ Hayan Kam Nakache/ Stéphane Kropf. Paris, France March 4th - April 18th.</p>
          <p>- All Together Different: A Survey of Working Artists on the Lower East Side , Manny Cantor Center. New York, USA, February 12th - April 1st.</p>
          <p>- 20in15,Woodward Gallery. New York, USA, January 10th - February 18th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2014</p>
          <p>- Sur-Real, Woodward Gallery. New York, USA, January 4th - February 22nd.</p>
          <p>- Loisaida: New York’s Lower East Side in the ‘80’s, Addison Gallery of American Art, Phillips Academy. Andover, Massachusetts, USA, April 2 nd - July 31st.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2013</p>
          <p>- Ghost Girl Corn Maze, Barton Orchard, Poughquag, New York in collaboration with David Phillips and Woodward Gallery. New York, USA, November 1st.</p>
          <p>- From the Street Up, Woodward Gallery. New York, USA, July 6th - 31st.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2012</p>
          <p>- Print, Woodward Gallery. New York, USA, January 5th - February 22nd.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2011</p>
          <p>- On Every Street, Samuel Owen Gallery, Greenwich, CT. Curated by Michael DeFeo in collaboration with Woodward Gallery, New York, USA, October 6th - November 3rd.</p>
          <p>- Art in the Streets, MoCA. Los Angeles, USA, April 17th - August 8th.</p>
          <p>- Pantheon: A History of Street Art in New York City , Donnell Library Building across from MoMA. New York, USA, April 2nd - 17th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2010</p>
          <p>- NEVER RECORDS: Part of No Longer Empty’s “Never Can Say Goodbye”, Curated by Manon Slome, Steven Evans, DIA Art Foundation, Asher Remy - Toledo at the former Tower Records at Broadway and West 4th. New York, USA, January.</p>
          <p>- BIG Paper Winter, featuring original works by Basquiat, Calder, DeKooning, Richard Hambleton, Franz Kline, Rauschenberg, Ad Reinhardt, Warhol; Woodward Gallery. New York, USA, January 16th - February 27th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2008</p>
          <p>- Off the Wall, From Vandalism to Urban Art, Wilde Gallery. Berlin, Germany, November 1st - December 13th.</p>
          <p>- Punk. No One is Innocent, Kunsthalle Wien. Vienna, Austria, May 16th - September 7th.</p>
          <p>- Stoked Benefit Art Auction, Milk Gallery at Phillips de Pury. New York, USA, April 15 th.</p>
          <p>- Works on Paper, Woodward Gallery at the Park Avenue Armory, 20th Anniversary. New York, USA, February 29th - March 3rd.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2007</p>
          <p>- Behind the Seen, Ad Hoc Art, featuring street work by Swoon, Keith Haring, Ron English, Shepard Fairey, Lee Quiñones, and others. Curated by Michael de Feo. New York, USA, December 13th - January 20th.</p>
          <p>- Contemporary Art Society Benefit Auction, Milwaukee Art Museum, Milwaukee, USA, November 2nd.</p>
          <p>- The Media: Reaction, Reflections, and Comments on the Culture of Media, featured with Jean-Michel Basquiat and Keith Haring, City of Brea Art Gallery. Brea, USA, May 27th - July 13th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2006</p>
          <p>- The New York Art Scene 1974-1984, Grey Art Gallery, New York. Traveling to The Andy Warhol Museum, Pittsburgh, USA, and The Austin Museum of Art, Austin, USA, May 27 th - September 3rd.</p>
          <p>- Gallery Artists ‘06-’07, Woodward Gallery, New York, USA, November 14th - January 6th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2005</p>
          <p>- Vintage East Village, Hal Bromm Gallery, New York, USA, January 20th - April 29th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2004</p>
          <p>- East Village USA, New Museum of Contemporary Art, New York, USA, December 9th, 2004 - March 19th, 2005.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1988</p>
          <p>- Venice Biennale, Venice, Italy, September 14th - November 23rd.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1986 - 1987</p>
          <p>- Public and Private: American Prints Today: The 24th National Print Exhibition, traveling exhibition: Brooklyn Museum, New York; Flint Institute of Arts, Flint; RISD, Rhode Island; Carnegie Museum, Pittsburgh; Walker Art Center, Minneapolis, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1985</p>
          <p>- Syndesthetics, PS 1, Long Island City, New York, USA.</p>
          <p>- Smart Art, Toesph Mosheck, Cambridge, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1984</p>
          <p>- Of the Streets, University of Colorado, Aspen, USA.</p>
          <p>- New Talent - New York, travelling exhibition organized by Sioux City Art Center, Iowa, USA.</p>
          <p>- East Village Artists, The Virginia Museum of Fine Arts, Richmond, USA.</p>
          <p>- East Village Scene, Institute of Contemporary Art, Philadelphia, USA.</p>
          <p>- Activated Walls, The Queens Museum, New York, USA.</p>
          <p>- Chill Out, Kenkeleba House, New York, USA.</p>
          <p>- Romance and Catastrophe, Zellermayer Gallerie, Berlin, Germany.</p>
          <p>- Sculpture Now, Anna Friebe Galerie, Koln, Germany.</p>
          <p>- 25,000 Sculptors from Across the USA, Civilian Warfare, New Yorkn, USA.</p>
          <p>- Aperto ‘84, BIENNALE DI VENEZIA, Venice, Italy.</p>
          <p>- Body Politic, Tower Gallery, New York, USA.</p>
          <p>- Arte di Frontiera, Galeria Communale d’arte Moderna, Bologna, Milan and Rome, Italy.</p>
          <p>- Situation, Bess Cutler Gallery, New York, USA.</p>
          <p>- Galleries of the Lower East Side, Artist Space, New York, USA.</p>
          <p>- Limbo, PS 1, Long Island City, New York, USA.</p>
          <p>- Totem, Bonnier Gallery, Charles Cowles Gallery, and Germans Van Eck Gallery, New York, USA.</p>
          <p>- Abstract Persona, Dramatis Personae Gallery, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1983</p>
          <p>- Abstract Persona, Dramatis Personae Gallery, New York, USA.</p>
          <p>- Piezo Electric, New York, USA.</p>
          <p>- Vancouver: art and artists 1931-1983, Vancouver Art Gallery, Vancouver, Canada.</p>
          <p>- Ronald Feldman Gallery, New York, USA.</p>
          <p>- Black and White Show, Kenkelba Gallery, New York, USA.</p>
          <p>- Gracie Mansion Gallery, New York, USA.</p>
          <p>- Galleri Cassandra, Norway.</p>
          <p>- Seventh Annual Forgione, New York, USA.</p>
          <p>- Fashion Moda, Bronx, New York, USA.</p>
          <p>- Tweed Gallery, New Jersey, USA.</p>
          <p>- Weatherspoon Art Gallery, Greensboro, USA.</p>
          <p>- Greenville County Museum of Art, Greenville, USA.</p>
          <p>- American Graffiti Gallery, Amsterdam, The Netherlands.</p>
          <p>- Group Material- Subculture, New York, USA.</p>
          <p>- Pier 34 Project, New York, USA.</p>
          <p>- International Running Center, New York, USA.</p>
          <p>- Terminal New York, Brooklyn, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1982</p>
          <p>- Club 57, New York, USA.</p>
          <p>- 5th International Apartment Festival, March 15th - 21th.</p>
          <p>- Storefront for Art and Architecture, New York, USA.</p>
          <p>- Pyramid, New York, USA.</p>
          <p>- Cheltenham Art Center, Cheltenham, USA.</p>
          <p>- Alexander Milliken Gallery, New York, USA.</p>
          <p>- Squat Theater, New York, USA.</p>
          <p>- Sarah Lawrence College Gallery, New York, USA.</p>
          <p>- Art on the Beach, Creative Time’s, New York, USA.</p>
          <p>- Second Story Books, Baltimore, USA.</p>
          <p>- The Real Estate Show, ABC No Rio, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1981</p>
          <p>- 9th Street Survival Show, New York, USA.</p>
          <p>- Club 57, New York, USA.</p>
          <p>- White Columns, New York, USA.</p>
          <p>- ABC No Rio, New York, USA.</p>
          <p>- Public Image Gallery, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1980</p>
          <p>- Times Square Show, Collaborative Projects Incorporated and Fashion Moda, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bolder"}}>PUBLIC COLLECTIONS</p>
          <br />
          <p>- Andy Warhol Museum, Pittsburgh, United States.</p>
          <p>- Museum of Modern Art, New York, United States.</p>
          <p id="end">- Zellermayer Gallerie, Berlin, Germany.</p>
          </div>
        </ul>
            <button className={MoreButton} id="moreBtn" onClick={() => {
                 var sel = document.querySelector('#more');
                 var selBtn = document.querySelector('#moreBtn');
                 console.log("in there");

                
                  if (sel.style.display === "none") {
                    sel.style.display = "inline";
                    selBtn.innerHTML = "Read more";
                  } else {
                    sel.style.display = "none";
                    selBtn.innerHTML = "Read less";
                }
                 
              }}
               >Read more</button>
        </div>
      </div>
     
      <div className={PhotosWrapper}>
       <Title title={"Past or Present"} />
        <div className={CarrouselWrapper2}>
          {/* <Carrousel images={infos.photos} />
           */}
           <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
        </div>
      </div>
      <div className={ButtonWrapper}>
            <Button
              alt=""
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.location.href = "/fr/contactez-nous";
                }
              }
            }
            >
              Contactez-nous
            </Button>
        </div>
    </div>
  );
};

export default RichardHambleton;